.App {
    height: calc(100vh - 110px);
    max-width: 1000px;
    margin: auto;
}

a {
    color: #fff;
    text-decoration: none;
}

a:hover {
    color: rgb(207,156,81);
}

.HomePageMessage {
    position: absolute;
    top: -110px;
    font-size: 40px;
    background: rgba(103,96,157,0.65);
    padding: 15px;
}


.AppHeader {
    height: 40px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    background: rgb(103,96,157);
    border-bottom: 10px solid rgb(207,156,81);
}

.AppHeader-item {
    text-transform: uppercase;
    margin: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.AppBody {
    background-image: url("img/tdb_logo.svg");
    background-size: 420px;
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box;
    min-height: 500px;
    height: 100%;
    width: 100%
}

.AppBodyOverlay {
    background: rgba(207,156,81,0.4);
    min-height: 500px;
    height: 100%;
    width: 100%;
    z-index: 1;	      
}

.AppBody-img {
    width: 100%;
    background-size: cover;
    background-repeat: repeat-y;
    opacity: 0.35;
}

.PagePanel {
    position: relative;
    top: 38px;
    xtop: 50%;
    xleft: 50%;
    xtransform: translate(-50%, -50%);
    width: 80%;
    max-width: 700px;
    min-height: 425px;
    height: 70vh;
    margin: auto;
    z-index: 2;
}

.AppFooter {
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    background: rgb(103,96,157);
    border-top: 10px solid rgb(207,156,81);
}

.AppFooter-item {
    margin: 10px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}

.Page-title {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: rgb(103,96,157);
    padding-left: 20px;
    padding-top:20px;
}

.Page-body {
    margin: 10px 5px 5px 10px;
    border-top: 2px solid rgb(207,156,81);
}

.Page-image {
    width: 350px;
    height: 263px;
}

.Page-testimonial {
    padding: 12px 12px 0px 12px;
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
}

.Page-testimonial-name {
    padding-right: 12px;
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
    text-align: right;
}

.Page-body a {
    font-weight: bold;
    color: rgb(207,156,81);
    text-decoration: none;
}

.Page-body a:hover {
    color: rgb(103,96,157);
}

.PageHome {
    position: absolute;
    width: 100%;
    top: 70px;
    text-align: center;
    background: none;
    margin-top: 12%;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
}

.PageHome .title {
    font-size: 10vw;
}

.PageHome .subtitle {
    font-size: 3vw;
}

.PageHome .supertitle {
    font-size: 4vw;
}

.PageContact {
    background: #eee;
    width: 100%;
    height: 100%;
    box-shadow: 10px 10px 5px rgba(50,50,50,0.4);
}

.PageContact p {
    margin-left: 50px;
    font-size: 16px;
}

.PageContact-contact {
    margin-top: 10px;
    margin-left: 150px;
}

.PageContact-header {
    font-size: 19px;
    font-weight: bold;
}

.PageContact-method {
    font-weight: bold;
    color: rgb(207,156,81);
    xcolor:  rgb(103,96,157);
}

.PageContact-address {
    font-style: italic;
}

.PageContact-address a {
    color: #000;
}

.PageContact a:hover {
    color:  rgb(103,96,157);
    xcolor: rgb(207,156,81);
}

.PageBio {
    background: #eee;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-shadow: 10px 10px 5px rgba(50,50,50,0.4);
}

.PageBio .Page-body {
    font-size: 14px;
    padding: 5px;
}

.PageBio-thumb {
    float: left;
    margin-right: 10px;
    margin-top: 4px;
    padding: 0px;
}

.PageBio-description {
    font-size: 16px;
}

.PageBio-bio {
    font-style: italic;
}

.PageClasses {
    background: #eee;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-shadow: 10px 10px 5px rgba(50,50,50,0.4);
}

.PageClasses .Page-body {
    font-size: 14px;
    padding: 5px;
}

.PageClasses .Page-body .date {
    font-size: 16px;
    font-weight: bold;
}

.PageClasses .Page-body .details {
    font-size: 12px;
    font-style: italic;
}

.PageEvents {
    background: #eee;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-shadow: 10px 10px 5px rgba(50,50,50,0.4);
}

.PageEvents .Page-body {
    font-size: 14px;
    padding: 5px;
}

.PageLessons {
    background: #eee;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-shadow: 10px 10px 5px rgba(50,50,50,0.4);
}

.PageLessons .Page-body {
    font-size: 14px;
    padding: 5px;
}


.PageGallery {
    background: #eee;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-shadow: 10px 10px 5px rgba(50,50,50,0.4);
}

.PageGallery-thumbs {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.PageGallery-thumb {
    width: 50px;
    height: 38px;
    margin: 5px 5px 0px 5px;
}

.PageGallery-thumb-selected {
    width: 44px;
    height: 32px;
    border: 3px solid rgb(207,156,81);
    margin: 5px 5px 0px 5px;
}

.PageGallery-label {
    display: none;
    width: 150px;
    height: 112px;
    padding: 0px 10px 10px 10px;
    font-size: 14px;
    color: rgb(207,156,81);
}

.PageGallery-carousel {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.PageGallery-arrow {
    font-size: 28px;
    font-weight: bold;
    padding: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.PageGallery-caption {
    padding-top: 5px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}


@media screen and (min-width: 1000px) {
    .PageHome {
	top: 0px;
    }
}

@media screen and (min-width: 900px) {
    .PageHome .title {
	font-size: 90px;
    }

    .PageHome .subtitle {
	font-size: 27px;
    }

    .PageHome .supertitle {
	font-size: 36px;
    }
}

@media screen and (max-width: 650px) {
    .Page-image {
	width: 250px;
	height: 188px;
    }    
}

@media screen and (max-width: 500px) {
    .AppBody {
	background-size: calc(100% - 10px);
	height: calc(100vh - 135px);
	min-height: 250px;
    }

    .AppBodyOverlay {
	height: calc(100vh - 135px);
	min-height: 250px;
    }
    
    .PagePanel {
	top: 0;
	left: 0;
	transform: none;
	margin-top: 0px;
	margin-bottom: 50px;
	width: 100%;
	min-height: 250px;
	height: calc(100vh - 135px);
    }

    .PageGallery, .PageBio, .PageContact .PageClasses, .PageLessons, .PageEvents {
	box-shadow: none;
    }

    
    .AppHeader {
	display: flex;
	flex-wrap: wrap;
	height: 40px;
	justify-content: space-evenly;
    }

    .AppHeader-item {
	margin: 2px;
	margin-bottom: 0px;
	font-size: 14px;
	font-weight: bold;
    }

    
    .AppFooter {
	height: 80px;
	font-size: 20px;
    }

    .AppFooter-item {
	font-size: 10px;
    }

    .PageContact p {
	margin-left: 5px;
	font-size: 14px;
    }
    
    .PageContact-contact {
	margin-left: 25px;
	font-size: 14px;
    }
    
    .Page-image {
	width: 200px;
	height: 150px;
    }
}

